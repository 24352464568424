import { createApp } from 'vue'
import Quasar from 'quasar/src/vue-plugin.js';
import Highcharts from 'highcharts'
import HighchartsVue from 'highcharts-vue'
import vennInit from 'highcharts/modules/venn'
import variablepieInit from 'highcharts/modules/variable-pie'
import accessibility from 'highcharts/modules/accessibility'
import heatmap from 'highcharts/modules/heatmap'
import exporting from 'highcharts/modules/exporting'
import barInit from 'highcharts/modules/data'
import UUID from 'vue-uuid'
import quasarUserOptions from './quasar-user-options'
import LoginApp from './LoginApp.vue'
import App from './App.vue'
import moduleStore from './store/index'
import router from '@/router'
import * as Sentry from '@sentry/vue'

accessibility(Highcharts)
exporting(Highcharts)
vennInit(Highcharts)
variablepieInit(Highcharts)
barInit(Highcharts)
heatmap(Highcharts)



// Пробуем авторизоваться
const token = localStorage.getItem('accessToken')
const appType = token ? App : LoginApp

const app = createApp(appType)

Sentry.init({
        app,
        dsn: 'https://9581726267ce9133d70e8c7c617dc4a7@sentry.nigma2.science-soft.ru/6',
        environment: process.env.VUE_APP_SENTRY_ENV,
        release: process.env.VUE_APP_SENTRY_VERSION,
        integrations: [
          new Sentry.Breadcrumbs({ console: !process.env.DEV }),
          new Sentry.BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router()),
          }),
          new Sentry.Replay({
            maskAllInputs: false,
            maskAllText: false,
            maskFn: (text) => text,
          }),
        ],
        normalizeDepth: 5,
      
        ignoreErrors: ['token is expired', 'Loading CSS chunk'],
      
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
      
        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ['localhost'],
      
        // Capture Replay for 10% of all sessions,
        // plus for 100% of sessions with an error
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
      
        sendDefaultPii: true,
      })

app
  .use(HighchartsVue as any)
  .use(Quasar, quasarUserOptions)
  .use(router())
  .use(moduleStore)
  .use(UUID)
  .mount('#app')
